<template>
  <div>
    <div class="list clear">
      <ul class="listBox">
        <li><span>商户名称</span><i class="hidden1">
              <Tooltip
                class="item"
                effect="light"
                :content="detail.mchName"
                placement="top"
              >
                <span>{{ detail.mchName }}</span>
              </Tooltip>
            </i></li>
          <li><span>商户简称</span><i class="hidden1">{{ detail.mchShortName }}</i></li>
          <li><span>商户编号</span><i class="hidden1">{{ detail.mchId }}</i></li>
          <li><span>账户编号</span><i class="hidden1">{{ detail.accountId }}</i></li>
          
          <!-- 为服务商时显示 -->
          <li><span>所属服务商</span><i class="hidden1">{{ detail.agentName }}</i></li>
          <li><span>服务商编号</span><i class="hidden1">{{ detail.agentId }}</i></li>

          <li><span>商户类型</span><i class="hidden1">{{ mchTypeLists[detail.mchType] }}</i></li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'"><span>商户证件类型</span><i class="hidden1">{{ licenceType[detail.licenceType * 1 - 1] }}</i></li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'"><span>商户证件编号</span><i class="hidden1">{{ detail.licenceNo }}</i></li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'"><span>联系人</span><i class="hidden1">{{ detail.contactName }}</i></li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'"><span>手机号码</span><i class="hidden1">{{ detail.contactTel }}</i></li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'"><span>邮箱</span><i class="hidden1">{{ detail.contactEmail }}</i></li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'"><span>客服电话</span><i class="hidden1">{{ detail.servicePhone }}</i></li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'"><span>网址</span><i class="hidden1">
              <Tooltip
                class="item"
                effect="light"
                :content="detail.mchSite"
                placement="top"
              >
                <span>{{ detail.mchSite }}</span>
              </Tooltip>
            </i></li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'"><span>联系地址</span><i class="hidden1">
              <Tooltip
                class="item"
                effect="light"
                :content="detail.contactAddress"
                placement="top"
              >
                <span>{{ detail.contactAddress }}</span>
              </Tooltip>
            </i></li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'"><span>法人姓名</span><i class="hidden1">{{ detail.legalPersonName }}</i></li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'"><span>法人身份证号码</span><i class="hidden1">{{ detail.idcardNo }}</i></li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'"><span>备注</span><i class="hidden1">
              <Tooltip
                class="item"
                effect="light"
                :content="detail.remark"
                placement="top"
              >
                <span>{{ detail.remark }}</span>
              </Tooltip>
            </i></li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'"><span>商户证件照片</span><i class="hidden1">
              <a
                v-if="detail.licencePicture"
                href="javascript:;"
                @click="showImgModal(detail.licencePicture)"
              >点击预览</a>
            </i></li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'"><span>法人身份证国徽照片</span><i class="hidden1">
              <a
                v-if="detail.idcardNormalPicture"
                href="javascript:;"
                @click="showImgModal(detail.idcardNormalPicture)"
              >点击预览</a>
            </i></li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'"><span>法人身份证人像照片</span><i class="hidden1">
              <a
                v-if="detail.idcardReversePicture"
                href="javascript:;"
                @click="showImgModal(detail.idcardReversePicture)"
              >点击预览</a>
            </i></li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'"><span>开户许可证</span><i class="hidden1">
              <a
                v-if="detail.accountOpeningLicense"
                href="javascript:;"
                @click="showImgModal(detail.accountOpeningLicense)"
              >点击预览</a>
            </i></li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'"><span>商户协议</span><i class="hidden1">
              <a
                v-if="detail.mchProtocol && detail.mchProtocol.length > 0"
                href="javascript:;"
                @click="showImgModals(detail.mchProtocol)"
              >点击预览</a>
            </i></li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'"><span>其他材料</span><i class="hidden1">
              <a
                v-if="detail.otherPicture && detail.otherPicture.length > 0"
                href="javascript:;"
                @click="showImgModals(detail.otherPicture)"
              >点击预览</a>
            </i></li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'"><span>创建时间</span><i class="hidden1">{{ detail.createTime }}</i></li>
          <li v-if="detail.mchType !== 'MICRO_ENTERPRISE'"><span>审核时间</span><i class="hidden1">{{ detail.checkTime ? detail.checkTime : "" }}</i></li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'"><span>经营类型</span><i class="hidden1">{{ microType[detail.microType] }}</i></li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'"><span>店铺地址</span><i class="hidden1">{{ detail.storeAddress }}</i></li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'"><span>店铺名称</span><i class="hidden1">{{ detail.storeName }}</i></li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'"><span>联系人</span><i class="hidden1">{{ detail.contactName }}</i></li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'"><span>手机号码</span><i class="hidden1">{{ detail.contactTel }}</i></li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'"><span>邮箱</span><i class="hidden1">{{ detail.contactEmail }}</i></li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'"><span>客服电话</span><i class="hidden1">{{ detail.servicePhone }}</i></li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'"><span>联系地址</span><i class="hidden1">
              <Tooltip
                class="item"
                effect="light"
                :content="detail.contactAddress"
                placement="top"
              >
                <span>{{ detail.contactAddress }}</span>
              </Tooltip>
            </i></li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'"><span>联系人身份证号码</span><i class="hidden1">{{ detail.idcardNo }}</i></li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'"><span>备注</span><i class="hidden1">
              <Tooltip
                class="item"
                effect="light"
                :content="detail.remark"
                placement="top"
              >
                <span>{{ detail.remark }}</span>
              </Tooltip>
            </i></li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'"><span>联系人身份证国徽照片</span><i class="hidden1">
              <a
                v-if="detail.idcardNormalPicture"
                href="javascript:;"
                @click="showImgModal(detail.idcardNormalPicture)"
              >点击预览</a>
            </i></li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'"><span>联系人身份证人像照片</span><i class="hidden1">
              <a
                v-if="detail.idcardReversePicture"
                href="javascript:;"
                @click="showImgModal(detail.idcardReversePicture)"
              >点击预览</a>
            </i></li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'"><span>商户协议</span><i class="hidden1">
              <a
                v-if="detail.mchProtocol && detail.mchProtocol.length > 0"
                href="javascript:;"
                @click="showImgModals(detail.mchProtocol)"
              >点击预览</a>
            </i></li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'"><span>店铺照片</span><i class="hidden1">
              <a
                v-if="detail.storePicture"
                href="javascript:;"
                @click="showImgModal(detail.storePicture)"
              >点击预览</a>
            </i></li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'"><span>创建时间</span><i class="hidden1">{{ detail.createTime }}</i></li>
          <li v-if="detail.mchType == 'MICRO_ENTERPRISE'"><span>审核时间</span><i class="hidden1"> {{ detail.checkTime ? detail.checkTime : "" }}</i></li>
          <li><span>最后修改时间</span><i class="hidden1">{{ detail.updateTime }}</i></li>
          <li><span>最后修改人</span><i class="hidden1">{{ detail.lastUpdatedUser }}</i></li>
      </ul>
    </div>
    <div
        class="cover_view"
        v-if="showImgUrls.length > 0"
        @click="showImgUrls = []"
      >
        <i
          v-if="showImgUrlIndex > 0"
          class="el-icon-arrow-left left"
          @click.stop="showImgUrlIndex--"
        ></i>
        <i
          v-if="showImgUrlIndex < showImgUrls.length - 1"
          class="el-icon-arrow-right right"
          @click.stop="showImgUrlIndex++"
        ></i>
        <a
          :href="showImgUrls[showImgUrlIndex].url"
          target="_blank"
        >
          <img
            :src="showImgUrls[showImgUrlIndex].url"
            alt="加载失败"
          />
        </a>
      </div>
    <!-- 查看图片 -->
    <BigImage :show-image.sync="showBigImg" :src="bigImgSrc" />
  </div>
</template>

<script>
import BigImage from '@/components/BigImage'
import address from "@/utils/address.json"
import industry from "@/utils/industry.json"
export default {
  components: { BigImage },
  filters: {
    filterMchType (val, options) {
      if (!val) { return '' }
      let result = options.filter(item => item.value === val)
      return (result.length > 0 && result[0].title) || ''
    },
    filterBussinessType (val, options) {
      if (!val) { return '' }
      let result = options.filter(item => item.id === val)
      return (result.length > 0 && result[0].name) || ''
    }
  },
  data () {
    return {
      licenceType: [
        "统一社会信用代码证书",
        "有偿服务许可证（军队医院适用）",
        "医疗机构执业许可证（军队医院适用）",
        "企业营业执照（挂靠企业的党组织适用）",
        "组织机构代码证（政府机关适用）",
        "社会团体法人登记证书",
        "民办非企业单位登记证书",
        "基金会法人登记证书",
        "慈善组织公开募捐资格证书",
        "农民专业合作社法人营业执照",
        "宗教活动场所登记证",
        "营业执照",
        "营业执照号码（多合一）",
        "事业单位法人证书",
        "其他证书/批文/证明",
      ],
      microType: [
        {
          title: "店铺场所",
          value: "MICRO_TYPE_STORE",
        },
        {
          title: "流动经营/便民服务",
          value: "MICRO_TYPE_MOBILE",
        },
        {
          title: "线上商品/服务交易",
          value: "MICRO_TYPE_ONLINE",
        },
      ],
      mchTypeLists: {
        "": "",
        ENTERPRISE: "企业",
        INDIVIDUAL_BUSINESS: "个体工商户",
        MICRO_ENTERPRISE: "小微商户",
        PUBLIC_INSTITUTION: "事业单位",
        PRIVATE_NONENTERPRISE_UNIT: "民办非企业单位",
        SOCIAL_ORGANIZATION: "社会团体",
      },
      mchType: [
        {
          title: "企业",
          value: "ENTERPRISE",
        },
        {
          title: "个体工商户",
          value: "INDIVIDUAL_BUSINESS",
        },
        {
          title: "小微商户",
          value: "MICRO_ENTERPRISE",
        },
        {
          title: "事业单位",
          value: "PUBLIC_INSTITUTION",
        },
        {
          title: "民办非企业单位",
          value: "PRIVATE_NONENTERPRISE_UNIT",
        },
        {
          title: "社会团体",
          value: "SOCIAL_ORGANIZATION",
        },
      ],
      industry,
      // 查看图片大图
      showBigImg: false,
      showImgUrls: [], //查看多个图片
      showImgUrlIndex: 0, //查看多个图片切换
      bigImgSrc: null
    }
  },
  props: {
    detail: {
      type: Object,
      default: {},
    },
  },
  methods: {
    // 查看图片
    showImgModal (cover) {
      this.showBigImg = true
      this.bigImgSrc = cover
    },
    // 浏览多个图片
    showImgModals(val) {
      this.showImgUrls = val ? JSON.parse(val) : []
      this.showImgUrlIndex = 0
    }
  },
};
</script>

<style lang="less" scoped>
</style>