<template>
  <div id="mian" class="container">
    <header class="header">
      <ul class="tab-tilte">
        <li
          :class="{ active: curTab == 'tab1' }"
          @click="handleClickTab('tab1')"
        >
          <span v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/INFO/SHOW'"
            >基本信息</span
          >
        </li>
        <li
          v-if="type !== 'add'"
          :class="{ active: curTab == 'tab2' }"
          @click="handleClickTab('tab2')"
        >
          <span v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/SETTLE'"
            >结算信息</span
          >
        </li>
        <li
          :class="{ active: curTab == 'tab3' }"
          @click="handleClickTab('tab3')"
        >
          <span v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNT/SHOW'"
            >关联分账方</span
          >
        </li>
        <li
          :class="{ active: curTab == 'tab4' }"
          @click="handleClickTab('tab4')"
        >
          <span v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNTBOOK'"
            >电子账簿</span
          >
        </li>
        <li
          v-if="type !== 'add'"
          :class="{ active: curTab == 'tab5' }"
          @click="handleClickTab('tab5')"
        >
          <span v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/PRODUCT'"
            >产品信息</span
          >
        </li>
      </ul>
    </header>
    <!-- 基本信息 -->
    <div v-if="curTab === 'tab1'" class="table">
      <div class="desc">
        <h5 class="title float_left">基本信息</h5>
        <div>
          <button
            v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/INFO/EDIT'"
            v-if="
              type === 'detail' &&
              detail.checkStatus !== 'PASS' &&
              activateStatusSwitch !== 'FROZEN'
            "
            class="ivu-btn edit-btn"
            @click="handleClickEdit"
          >
            编辑
          </button>
        </div>
      </div>
      <template v-if="type === 'detail'">
        <Info :detail="detail"></Info>
        <div class="status">
          <span class="label">审核状态</span>
          <p>{{ checkStatus[detail.checkStatus] }}</p>
          <span
            v-if="detail.checkStatus === 'REJECT'"
            class="check-msg ellipsis"
            >{{ detail.checkMsg || "" }}</span
          >
          <!-- <div style="flex: 1;">
            <button
              v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/INFO/PASS'"
              @click="auditShop('PASS')"
              v-if="detail.checkStatus != 'PASS'"
              :disabled="detail.checkStatus == 'PASS'"
              class="ivu-btn overBt active"
              type="primary"
            >通过</button>
            <button
              @click="auditShop('REJECT')"
              v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/INFO/REJECT'"
              v-if="detail.checkStatus != 'PASS'"
              :disabled="
                detail.checkStatus == 'REJECT' || detail.checkStatus == 'PASS'
              "
              class="ivu-btn returnBt"
              type="primary"
            >驳回</button>
          </div> -->
        </div>
        <div class="status">
          <span class="label">商户状态</span>
          <p>{{ activateStatus[detail.activateStatus] }}</p>
          <!-- <div style="flex: 1;">
            <button
              @click="setShopStatus('ACTIVATE')"
              v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/INFO/ACTIVATE'"
              v-if="detail.checkStatus == 'PASS'"
              class="ivu-btn returnBt"
              :class="detail.activateStatus == 'ACTIVATE' ? 'active' : ''"
              :disabled="detail.activateStatus == 'ACTIVATE'"
            >激活</button>
            <button
              @click="setShopStatus('CLOSED')"
              v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/INFO/CLOSED'"
              v-if="detail.checkStatus == 'PASS'"
              class="ivu-btn returnBt"
              :class="detail.activateStatus == 'CLOSED' ? 'active' : ''"
              :disabled="detail.activateStatus == 'CLOSED'"
            >关闭</button>
            <button
              @click="setShopStatus('FROZEN')"
              v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/INFO/FROZEN'"
              v-if="detail.checkStatus == 'PASS'"
              class="ivu-btn returnBt"
              :class="detail.activateStatus == 'FROZEN' ? 'active' : ''"
              :disabled="detail.activateStatus == 'FROZEN'"
            >冻结</button>
          </div> -->
        </div>
      </template>
      <template v-if="['edit', 'add'].includes(type)">
        <div class="tab1-input">
          <Form
            ref="formValidate"
            class="form"
            :model="formData"
            :label-width="80"
          >
            <FormItem class="form-item" prop="mchName">
              <span class="tab-label"><i>*</i>商户名称 </span>
              <Input
                v-model="formData.mchName"
                class="inputs"
                size="large"
                placeholder="输入商户名称"
              ></Input>
            </FormItem>
            <FormItem class="form-item" prop="mchShortName">
              <span class="tab-label"><i>*</i>商户简称 </span>
              <Input
                v-model="formData.mchShortName"
                class="inputs"
                size="large"
                placeholder="输入商户名称"
              ></Input>
            </FormItem>
            <FormItem class="form-item" prop="mchType">
              <span class="tab-label"><i>*</i>商户类型 </span>
              <Select
                v-model="formData.mchType"
                size="large"
                placeholder="选择商户类型"
              >
                <Option
                  v-for="item in mchType"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.title }}</Option
                >
              </Select>
            </FormItem>
            <FormItem
              v-if="formData.mchType !== 'MICRO_ENTERPRISE'"
              class="form-item"
              prop="bussinessType"
            >
              <span class="tab-label"><i>*</i>商户证件类型 </span>
              <Select
                v-model="formData.licenceType"
                size="large"
                placeholder="选择商户证件类型"
              >
                <Option
                  v-for="(item, index) in licenceType"
                  :value="index + 1"
                  :key="item"
                  >{{ item }}</Option
                >
              </Select>
            </FormItem>
            <FormItem
              v-if="formData.mchType !== 'MICRO_ENTERPRISE'"
              class="form-item"
              prop="licenceNo"
            >
              <span class="tab-label"><i>*</i>商户证件编号 </span>
              <Input
                v-model="formData.licenceNo"
                class="inputs"
                size="large"
                placeholder="输入商户证件编号"
              ></Input>
            </FormItem>
            <FormItem
              v-if="formData.mchType !== 'MICRO_ENTERPRISE'"
              class="form-item"
              prop="contactName"
            >
              <span class="tab-label"><i>*</i>联系人 </span>
              <Input
                v-model="formData.contactName"
                class="inputs"
                size="large"
                placeholder="输入联系人"
              ></Input>
            </FormItem>
            <FormItem
              v-if="formData.mchType !== 'MICRO_ENTERPRISE'"
              class="form-item"
              prop="contactTel"
            >
              <span class="tab-label"><i>*</i>手机号码 </span>
              <Input
                v-model="formData.contactTel"
                maxlength="11"
                class="inputs"
                size="large"
                placeholder="输入客服电话"
              ></Input>
            </FormItem>
            <FormItem
              v-if="formData.mchType !== 'MICRO_ENTERPRISE'"
              class="form-item"
              prop="contactEmail"
            >
              <span class="tab-label"><i>*</i>邮箱 </span>
              <Input
                v-model="formData.contactEmail"
                class="inputs"
                size="large"
                placeholder="输入邮箱"
              ></Input>
            </FormItem>
            <FormItem
              v-if="formData.mchType !== 'MICRO_ENTERPRISE'"
              class="form-item"
              prop="servicePhone"
            >
              <span class="tab-label"><i>*</i>客服电话 </span>
              <Input
                v-model="formData.servicePhone"
                maxlength="11"
                class="inputs"
                size="large"
                placeholder="输入客服电话"
              ></Input>
            </FormItem>
            <FormItem
              v-if="formData.mchType !== 'MICRO_ENTERPRISE'"
              class="form-item"
              prop="mchSite"
            >
              <span class="tab-label">网址 </span>
              <Input
                v-model="formData.mchSite"
                class="inputs"
                size="large"
                placeholder="输入网址"
              ></Input>
            </FormItem>
            <FormItem
              v-if="formData.mchType !== 'MICRO_ENTERPRISE'"
              class="form-item"
              prop="contactAddress"
            >
              <span class="tab-label"><i>*</i>联系地址 </span>
              <Input
                v-model="formData.contactAddress"
                class="inputs"
                size="large"
                placeholder="输入联系地址"
              ></Input>
            </FormItem>
            <FormItem
              v-if="formData.mchType !== 'MICRO_ENTERPRISE'"
              class="form-item"
              prop="legalPersonName"
            >
              <span class="tab-label"><i>*</i>法人姓名 </span>
              <Input
                v-model="formData.legalPersonName"
                class="inputs"
                size="large"
                placeholder="输入法人姓名"
              ></Input>
            </FormItem>
            <FormItem
              v-if="formData.mchType !== 'MICRO_ENTERPRISE'"
              class="form-item"
              prop="idcardNo"
            >
              <span class="tab-label"><i>*</i>法人身份证号码 </span>
              <Input
                v-model="formData.idcardNo"
                class="inputs"
                size="large"
                placeholder="输入法人手机号码"
              ></Input>
            </FormItem>
            <FormItem
              v-if="formData.mchType !== 'MICRO_ENTERPRISE'"
              class="form-item"
              prop="remark"
            >
              <span class="tab-label">备注 </span>
              <Input
                v-model="formData.remark"
                class="inputs"
                size="large"
                placeholder="输入备注"
              ></Input>
            </FormItem>
            <FormItem
              v-if="formData.mchType == 'MICRO_ENTERPRISE'"
              class="form-item"
              prop="microType"
            >
              <span class="tab-label"><i>*</i>经营类型 </span>
              <Select
                v-model="formData.microType"
                size="large"
                placeholder="选择经营类型"
              >
                <Option
                  v-for="item in microType"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.title }}</Option
                >
              </Select>
            </FormItem>
            <FormItem
              v-if="formData.mchType == 'MICRO_ENTERPRISE'"
              class="form-item"
              prop="storeAddress"
            >
              <span class="tab-label"><i>*</i>店铺地址 </span>
              <Input
                v-model="formData.storeAddress"
                class="inputs"
                size="large"
                placeholder="输入店铺地址"
              ></Input>
            </FormItem>
            <FormItem
              v-if="formData.mchType == 'MICRO_ENTERPRISE'"
              class="form-item"
              prop="storeName"
            >
              <span class="tab-label"><i>*</i>店铺名称 </span>
              <Input
                v-model="formData.storeName"
                class="inputs"
                size="large"
                placeholder="输入店铺名称"
              ></Input>
            </FormItem>
            <FormItem
              v-if="formData.mchType == 'MICRO_ENTERPRISE'"
              class="form-item"
              prop="contactName"
            >
              <span class="tab-label"><i>*</i>联系人 </span>
              <Input
                v-model="formData.contactName"
                class="inputs"
                size="large"
                placeholder="输入联系人"
              ></Input>
            </FormItem>
            <FormItem
              v-if="formData.mchType == 'MICRO_ENTERPRISE'"
              class="form-item"
              prop="contactTel"
            >
              <span class="tab-label"><i>*</i>手机号码 </span>
              <Input
                v-model="formData.contactTel"
                maxlength="11"
                class="inputs"
                size="large"
                placeholder="输入客服电话"
              ></Input>
            </FormItem>
            <FormItem
              v-if="formData.mchType == 'MICRO_ENTERPRISE'"
              class="form-item"
              prop="contactEmail"
            >
              <span class="tab-label"><i>*</i>邮箱 </span>
              <Input
                v-model="formData.contactEmail"
                class="inputs"
                size="large"
                placeholder="输入邮箱"
              ></Input>
            </FormItem>
            <FormItem
              v-if="formData.mchType == 'MICRO_ENTERPRISE'"
              class="form-item"
              prop="servicePhone"
            >
              <span class="tab-label"><i>*</i>客服电话 </span>
              <Input
                v-model="formData.servicePhone"
                maxlength="11"
                class="inputs"
                size="large"
                placeholder="输入客服电话"
              ></Input>
            </FormItem>
            <FormItem
              v-if="formData.mchType == 'MICRO_ENTERPRISE'"
              class="form-item"
              prop="contactAddress"
            >
              <span class="tab-label"><i>*</i>联系地址 </span>
              <Input
                v-model="formData.contactAddress"
                class="inputs"
                size="large"
                placeholder="输入联系地址"
              ></Input>
            </FormItem>
            <FormItem
              v-if="formData.mchType == 'MICRO_ENTERPRISE'"
              class="form-item"
              prop="idcardNo"
            >
              <span class="tab-label"><i>*</i>联系人身份证号码 </span>
              <Input
                v-model="formData.idcardNo"
                class="inputs"
                size="large"
                placeholder="输入联系人身份证号码"
              ></Input>
            </FormItem>
            <FormItem
              v-if="formData.mchType == 'MICRO_ENTERPRISE'"
              class="form-item"
              prop="remark"
            >
              <span class="tab-label">备注 </span>
              <Input
                v-model="formData.remark"
                class="inputs"
                size="large"
                placeholder="输入备注"
              ></Input>
            </FormItem>
          </Form>
        </div>
        <div
          class="tab1-upload"
          v-show="formData.mchType !== 'MICRO_ENTERPRISE'"
        >
          <div class="upload-form-item" prop="licencePicture">
            <span class="tab-label"><i>*</i>商户证件 </span>
            <Upload
              v-model="formData.licencePicture"
              type="drag"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              :action="uploadUrl"
              :on-success="licencePictureSuccess"
            >
              <div class="upload-img">
                <img
                  v-if="formData.licencePicture"
                  :src="formData.licencePicture"
                  class="avatar"
                />
                <Icon
                  v-else
                  type="ios-add"
                  size="52"
                  style="color: #3399ff"
                ></Icon>
                <Icon
                  v-if="formData.licencePicture"
                  type="md-close"
                  size="25"
                  class="icon-delete"
                  style="color: #3399ff"
                  @click.stop="formData.licencePicture = ''"
                ></Icon>
              </div>
            </Upload>
          </div>
          <div class="upload-form-item" prop="idcardNormalPicture">
            <span class="tab-label"><i>*</i>法人身份证国徽照片 </span>
            <Upload
              v-model="formData.idcardNormalPicture"
              type="drag"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              :action="uploadUrl"
              :on-success="idcardNormalPictureSuccess"
            >
              <div class="upload-img">
                <img
                  v-if="formData.idcardNormalPicture"
                  :src="formData.idcardNormalPicture"
                  class="avatar"
                />
                <Icon
                  v-else
                  type="ios-add"
                  size="52"
                  style="color: #3399ff"
                ></Icon>
                <Icon
                  v-if="formData.idcardNormalPicture"
                  type="md-close"
                  size="25"
                  class="icon-delete"
                  style="color: #3399ff"
                  @click.stop="formData.idcardNormalPicture = ''"
                ></Icon>
              </div>
            </Upload>
          </div>
          <div class="upload-form-item" prop="idcardReversePicture">
            <span class="tab-label"><i>*</i>法人身份证人像照片 </span>
            <Upload
              v-model="formData.idcardReversePicture"
              type="drag"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              :action="uploadUrl"
              :on-success="idcardReversePictureSuccess"
            >
              <div class="upload-img">
                <img
                  v-if="formData.idcardReversePicture"
                  :src="formData.idcardReversePicture"
                  class="avatar"
                />
                <Icon
                  v-else
                  type="ios-add"
                  size="52"
                  style="color: #3399ff"
                ></Icon>
                <Icon
                  v-if="formData.idcardReversePicture"
                  type="md-close"
                  size="25"
                  class="icon-delete"
                  style="color: #3399ff"
                  @click.stop="formData.idcardReversePicture = ''"
                ></Icon>
              </div>
            </Upload>
          </div>
          <div class="upload-form-item" prop="accountOpeningLicense">
            <span class="tab-label"><i>*</i>开户许可证 </span>
            <Upload
              v-model="formData.accountOpeningLicense"
              type="drag"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              :action="uploadUrl"
              :on-success="accountOpeningLicenseSuccess"
            >
              <div class="upload-img">
                <img
                  v-if="formData.accountOpeningLicense"
                  :src="formData.accountOpeningLicense"
                  class="avatar"
                />
                <Icon
                  v-else
                  type="ios-add"
                  size="52"
                  style="color: #3399ff"
                ></Icon>
                <Icon
                  v-if="formData.accountOpeningLicense"
                  type="md-close"
                  size="25"
                  class="icon-delete"
                  style="color: #3399ff"
                  @click.stop="formData.accountOpeningLicense = ''"
                ></Icon>
              </div>
            </Upload>
          </div>
        </div>
        <div
          class="tab1-upload"
          v-show="formData.mchType == 'MICRO_ENTERPRISE'"
        >
          <div class="upload-form-item" prop="idcardNormalPicture">
            <span class="tab-label"><i>*</i>法人身份证国徽照片 </span>
            <Upload
              v-model="formData.idcardNormalPicture"
              type="drag"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              :action="uploadUrl"
              :on-success="idcardNormalPictureSuccess"
            >
              <div class="upload-img">
                <img
                  v-if="formData.idcardNormalPicture"
                  :src="formData.idcardNormalPicture"
                  class="avatar"
                />
                <Icon
                  v-else
                  type="ios-add"
                  size="52"
                  style="color: #3399ff"
                ></Icon>
                <Icon
                  v-if="formData.idcardNormalPicture"
                  type="md-close"
                  size="25"
                  class="icon-delete"
                  style="color: #3399ff"
                  @click.stop="formData.idcardNormalPicture = ''"
                ></Icon>
              </div>
            </Upload>
          </div>
          <div class="upload-form-item" prop="idcardReversePicture">
            <span class="tab-label"><i>*</i>法人身份证人像照片 </span>
            <Upload
              v-model="formData.idcardReversePicture"
              type="drag"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              :action="uploadUrl"
              :on-success="idcardReversePictureSuccess"
            >
              <div class="upload-img">
                <img
                  v-if="formData.idcardReversePicture"
                  :src="formData.idcardReversePicture"
                  class="avatar"
                />
                <Icon
                  v-else
                  type="ios-add"
                  size="52"
                  style="color: #3399ff"
                ></Icon>
                <Icon
                  v-if="formData.idcardReversePicture"
                  type="md-close"
                  size="25"
                  class="icon-delete"
                  style="color: #3399ff"
                  @click.stop="formData.idcardReversePicture = ''"
                ></Icon>
              </div>
            </Upload>
          </div>
          <div class="upload-form-item" prop="accountOpeningLicense">
            <span class="tab-label"><i>*</i>店铺照片 </span>
            <Upload
              v-model="formData.storePicture"
              type="drag"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              :action="uploadUrl"
              :on-success="storePictureSuccess"
            >
              <div class="upload-img">
                <img
                  v-if="formData.storePicture"
                  :src="formData.storePicture"
                  class="avatar"
                />
                <Icon
                  v-else
                  type="ios-add"
                  size="52"
                  style="color: #3399ff"
                ></Icon>
                <Icon
                  v-if="formData.storePicture"
                  type="md-close"
                  size="25"
                  class="icon-delete"
                  style="color: #3399ff"
                  @click.stop="formData.storePicture = ''"
                ></Icon>
              </div>
            </Upload>
          </div>
        </div>
        <!-- 材料 -->
        <div class="upload_wj clear">
          <div class="upload_wj_left">
            <h5>商户协议</h5>
            <el-upload
              class="upload-demo"
              :before-upload="beforeUpload"
              :action="uploadUrl"
              :on-preview="handlePreview"
              :on-success="mchProtocolSuccess"
              :on-remove="mchProtocolRemove"
              multiple
              :file-list="mchProtocolList"
            >
              <Button size="small" type="primary" class="ivu-btn"
                >点击上传</Button
              >
            </el-upload>
          </div>
          <div
            class="upload_wj_right"
            v-show="formData.mchType !== 'MICRO_ENTERPRISE'"
          >
            <h5>其他材料</h5>
            <!-- :before-upload="beforeUpload" -->
            <el-upload
              class="upload-demo"
              :action="uploadUrl"
              :on-preview="handlePreview"
              :on-success="otherPictureSuccess"
              :on-remove="otherPictureRemove"
              multiple
              :file-list="otherPictureList"
            >
              <Button size="small" type="primary" class="ivu-btn"
                >点击上传</Button
              >
            </el-upload>
          </div>
        </div>
        <div class="tab1-footer">
          <div v-if="type === 'edit'">
            <Button @click="handleClickCancel">返回</Button>
            <Button type="primary" @click="onSubmit">保存</Button>
          </div>
          <div v-if="type === 'add'">
            <Button type="primary" @click="nextStep('tab1')">下一步</Button>
          </div>
        </div>
      </template>
    </div>
    <!-- 结算信息 -->
    <div v-if="curTab === 'tab2'" class="table">
      <div class="desc">
        <h5 class="title float_left">结算信息</h5>
      </div>
      <Table :columns="settleColumns" :data="settleData"></Table>
    </div>
    <!-- 关联分账方 -->
    <div v-if="curTab === 'tab3'" class="table tab3">
      <div class="desc">
        <h5 class="title float_left">关联分账方</h5>
      </div>
      <Table :columns="accountColumns" :data="accountData">
        <template slot="action" slot-scope="{ row }">
          <a
            v-if="activateStatusSwitch !== 'FROZEN'"
            v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNT/EDIT'"
            @click="editAccount(row)"
            >编辑</a
          >
        </template>
      </Table>
      <a
        v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNT/EDIT'"
        v-if="type === 'add' && accountData.length === 0"
        class="add-user-btn"
        @click="addAccount"
        >+新增分账方信息</a
      >
      <div v-if="type === 'add'" class="tab1-footer">
        <div>
          <Button @click="curTab = 'tab1'">上一步</Button>
          <Button type="primary" @click="nextStep('tab3')">下一步</Button>
        </div>
      </div>
    </div>
    <!-- 电子账簿 -->
    <div v-if="curTab === 'tab4'" class="table">
      <template v-if="accBookModel === 'add'">
        <div class="desc">
          <h5 class="title float_left">电子账簿信息</h5>
        </div>
        <Table :columns="accBooChannelColumns" :data="accBooChannel">
          <template slot="channelConfig" slot-scope="{ row }">
            <a
              v-if="activateStatusSwitch !== 'FROZEN'"
              v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNTBOOK/EDIT'"
              @click="accBookModel = 'config'"
              >配置</a
            >
          </template>
          <template slot="action" slot-scope="{ row }">
            <a
              v-if="activateStatusSwitch !== 'FROZEN'"
              v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNTBOOK/EDIT'"
              @click="editAccBooChannel(row)"
              >编辑</a
            >
          </template>
        </Table>
        <a
          v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNTBOOK/EDIT'"
          v-if="type === 'add' && accBooChannel.length === 0"
          class="add-user-btn"
          @click="addAccBooChannel"
          >+新增电子账簿渠道信息</a
        >
        <div v-if="type === 'add'" class="tab1-footer">
          <div>
            <Button @click="curTab = 'tab3'">上一步</Button>
            <Button type="primary" @click="handleClickSubmit">保存</Button>
          </div>
        </div>
      </template>
      <template v-if="accBookModel === 'config'">
        <div class="desc">
          <h5 class="title float_left">电子账簿信息</h5>
        </div>
        <Table :columns="accountColumns1" :data="tableData1">
          <template slot="action" slot-scope="{ row }">
            <a
              v-if="activateStatusSwitch !== 'FROZEN'"
              v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNTBOOK/EDIT'"
              style="margin-right: 10px"
              @click="editBookAccount('tableData1', row)"
              >编辑</a
            >
            <a
              v-if="activateStatusSwitch !== 'FROZEN'"
              v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNTBOOK/DELETE'"
              @click="deleteBookAccount('tableData1')"
              >删除</a
            >
          </template>
        </Table>
        <a
          v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNTBOOK/EDIT'"
          v-if="tableData1.length === 0"
          class="add-user-btn"
          @click="addAccBooConfigShow = true"
          >+新增电子账簿渠道信息</a
        >
        <div class="desc" style="margin-top: 20px">
          <h5 class="title float_left">出金账户信息</h5>
        </div>
        <Table :columns="accountColumns2" :data="tableData2">
          <template slot="action" slot-scope="{ row }">
            <a
              v-if="activateStatusSwitch !== 'FROZEN'"
              v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNTBOOK/EDIT'"
              style="margin-right: 10px"
              @click="editBookAccount('tableData2', row)"
              >编辑</a
            >
            <a
              v-if="activateStatusSwitch !== 'FROZEN'"
              v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNTBOOK/DELETE'"
              @click="deleteBookAccount('tableData2')"
              >删除</a
            >
          </template>
        </Table>
        <a
          v-auth="'BASIC:FILEMANA:BUSINESSLIST/DETAIL/ACCOUNTBOOK/EDIT'"
          v-if="tableData2.length === 0"
          class="add-user-btn"
          @click="addAccBooConfigShow2 = true"
          >+新增出金账户信息</a
        >
        <div class="tab1-footer">
          <div>
            <Button @click="accBookModel = 'add'">返回</Button>
          </div>
        </div>
      </template>
    </div>
    <!-- 平台产品信息 -->
    <div v-if="curTab === 'tab5'" class="table">
      <div class="desc">
        <h5 class="title float_left">平台产品信息</h5>
      </div>
      <Table :columns="productColumns" :data="productData">
        <template slot="mchName">
          <span>{{ detail.mchName }}</span>
        </template>
      </Table>
      <div v-if="type === 'add'" class="tab1-footer">
        <div>
          <Button @click="curTab = 'tab1'">上一步</Button>
          <Button type="primary" @click="curTab = 'tab6'">下一步</Button>
        </div>
      </div>
    </div>
    <!-- 分账方信息弹窗 -->
    <Modal
      v-model="addUserShow"
      class="tab3"
      title="分账方信息"
      width="490"
      @on-cancel="addUserShow = false"
      @on-ok="sureAdd"
    >
      <Form class="tab3-form tab3-form-account" :model="mchFormData">
        <FormItem class="form-item" prop="mchName">
          <span class="tab-label"><i>*</i>商户名称 </span>
          <Input
            v-model="mchFormData.mchName"
            class="inputs"
            size="large"
            placeholder="输入商户名称"
            disabled
          ></Input>
        </FormItem>
        <FormItem class="form-item" prop="allocMchId">
          <span class="tab-label"><i>*</i>分账方编号 </span>
          <Select
            class="inputs"
            v-model="mchFormData.allocMchId"
            placeholder="选择分账方编号"
            size="large"
            tabindex="4"
          >
            <Option
              v-for="(v, i) in subNumber"
              :key="i"
              :label="v.allocMchId"
              :value="v.allocMchId"
            ></Option>
          </Select>
        </FormItem>
      </Form>
      <span slot="footer" class="dialog-footer">
        <Button class="exitBt" size="default" @click="addUserShow = false"
          >取 消</Button
        >
        <Button class="addBt" size="default" type="primary" @click="sureAdd"
          >确 定</Button
        >
      </span>
    </Modal>
    <Modal
      v-model="addAccBooChannelShow"
      class="tab3"
      title="电子账簿渠道信息"
      width="490"
      @on-cancel="addAccBooChannelShow = false"
      @on-ok="sureAdd"
    >
      <Form class="tab3-form tab3-form-account" :model="accountBook">
        <FormItem class="form-item" prop="mchName">
          <span class="tab-label"><i>*</i>渠道名称 </span>
          <Input
            v-model="formData.mchName"
            class="inputs"
            size="large"
            placeholder="输入渠道名称"
            disabled
          ></Input>
        </FormItem>
        <FormItem class="form-item" prop="mchChannelId">
          <span class="tab-label"><i>*</i>商户号 </span>
          <Input
            v-model="mchChannelId"
            class="inputs"
            size="large"
            placeholder="输入商户号"
          ></Input>
        </FormItem>
      </Form>
      <span slot="footer" class="dialog-footer">
        <Button
          class="exitBt"
          size="default"
          @click="addAccBooChannelShow = false"
          >取 消</Button
        >
        <Button class="addBt" size="default" type="primary" @click="sureAddBook"
          >确 定</Button
        >
      </span>
    </Modal>
    <Modal
      v-model="addAccBooConfigShow"
      class="tab3"
      :title="tableData1.length > 0 ? '编辑电子账簿信息' : '新增电子账簿信息'"
      width="490"
      @on-cancel="addAccBooConfigShow = false"
      @on-ok="sureAdd"
    >
      <Form
        class="tab3-form tab3-form-account"
        style="margin-bottom: -15px"
        :model="bookConfig"
      >
        <FormItem class="form-item" prop="accountName">
          <span class="tab-label"><i>*</i>户名 </span>
          <Input
            v-model="bookConfig.accountName"
            class="inputs"
            size="large"
            placeholder="输入户名"
          ></Input>
        </FormItem>
        <FormItem class="form-item" prop="accountNo">
          <span class="tab-label"><i>*</i>账号 </span>
          <Input
            v-model="bookConfig.accountNo"
            class="inputs"
            size="large"
            placeholder="输入账号"
          ></Input>
        </FormItem>
      </Form>
      <Form
        class="tab3-form tab3-form-account"
        style="margin-bottom: -15px"
        :model="bookConfig"
      >
        <FormItem class="form-item" prop="accountBankName">
          <span class="tab-label"><i>*</i>银行 </span>
          <Input
            v-model="bookConfig.accountBankName"
            class="inputs"
            size="large"
            placeholder="输入银行"
          ></Input>
        </FormItem>
        <FormItem class="form-item" prop="accountBranchBankName">
          <span class="tab-label"><i>*</i>支行 </span>
          <Input
            v-model="bookConfig.accountBranchBankName"
            class="inputs"
            size="large"
            placeholder="输入支行"
          ></Input>
        </FormItem>
      </Form>
      <Form
        class="tab3-form tab3-form-account"
        style="margin-bottom: -15px"
        :model="bookConfig"
      >
        <FormItem class="form-item" prop="accountOpen">
          <span class="tab-label"><i>*</i>开户地 </span>
          <Input
            v-model="bookConfig.accountOpen"
            class="inputs"
            size="large"
            placeholder="输入开户地"
          ></Input>
        </FormItem>
      </Form>
      <span slot="footer" class="dialog-footer">
        <Button
          class="exitBt"
          size="default"
          @click="addAccBooConfigShow = false"
          >取 消</Button
        >
        <Button
          class="addBt"
          size="default"
          type="primary"
          @click="sureAddBookConfig(1)"
          >确 定</Button
        >
      </span>
    </Modal>
    <Modal
      v-model="addAccBooConfigShow2"
      class="tab3"
      :title="tableData2.length > 0 ? '编辑出金账户信息' : '新增出金账户信息'"
      width="490"
      @on-cancel="addAccBooConfigShow2 = false"
      @on-ok="sureAdd"
    >
      <Form
        class="tab3-form tab3-form-account"
        style="margin-bottom: -15px"
        :model="bookConfig"
      >
        <FormItem class="form-item" prop="receiveAccountNo">
          <span class="tab-label"><i>*</i>收款账号 </span>
          <Input
            v-model="bookConfig.receiveAccountNo"
            class="inputs"
            size="large"
            placeholder="输入收款账号"
          ></Input>
        </FormItem>
        <FormItem class="form-item" prop="receiveAccountBankName">
          <span class="tab-label"><i>*</i>开户行 </span>
          <Input
            v-model="bookConfig.receiveAccountBankName"
            class="inputs"
            size="large"
            placeholder="输入开户行"
          ></Input>
        </FormItem>
      </Form>
      <Form
        class="tab3-form tab3-form-account"
        style="margin-bottom: -15px"
        :model="bookConfig"
      >
        <FormItem class="form-item" prop="receiveAccountName">
          <span class="tab-label"><i>*</i>户名 </span>
          <Input
            v-model="bookConfig.receiveAccountName"
            class="inputs"
            size="large"
            placeholder="输入户名"
          ></Input>
        </FormItem>
      </Form>
      <span slot="footer" class="dialog-footer">
        <Button
          class="exitBt"
          size="default"
          @click="addAccBooConfigShow2 = false"
          >取 消</Button
        >
        <Button
          class="addBt"
          size="default"
          type="primary"
          @click="sureAddBookConfig(2)"
          >确 定</Button
        >
      </span>
    </Modal>
  </div>
</template>

<script>
import * as imageConversion from "image-conversion";
import { URL_API } from "@/utils/apiUrl.js";
import Attach from "./components/attach";
import Info from "./components/info2";
import industry from "@/utils/industry.json";
import address from "@/utils/address.json";
import { mapState, mapMutations } from "vuex";
import { formatDate } from "@/utils/common.js";
import {
  shopDetail,
  setShop,
  auditShop,
  resend,
  setShopStatus,
  mchAllocEdit,
  searAlloc,
  addShop,
  putsupplementEidt,
  postAccountBookChannelAdd,
} from "@/api/basic/fileManage.js";
export default {
  components: { Attach, Info },
  data() {
    return {
      isImge: true, //判断商户协议上传的是不是图片
      activateStatusSwitch: "",
      curTab: "tab1",
      type: "detail",
      buttonFlag: false,
      options: {
        shortcuts: [
          {
            text: "长期",
            value() {
              return new Date("2999-01-01");
            },
          },
        ],
      },
      mchChannelId: "",
      formData: {
        mchModel: "3", // 0单商户入网 1服务商入网 3电子账簿
        mchName: "", //商户名称
        mchShortName: "", //商户简称
        mchType: "", //商户类型，
        licenceType: null, //商户证件类型
        licenceNo: "", //商户证件编号
        contactName: "", //联系人
        contactTel: "", //联系电话
        contactEmail: "", //邮箱
        contactAddress: "", //联系地址
        legalPersonName: "", //法人姓名
        idcardNo: "", //身份证号码（法人或联系人）
        remark: "", //备注 false
        licencePicture: "", //商户证件照片URL
        servicePhone: "", //客服电话
        mchSite: "", //网址 false
        accountOpeningLicense: "", //开户许可证图片URL true
        mchProtocol: "", //商户协议
        otherPicture: "", //其他材料链接URL false
        microType: null, // false小微经营类型，
        storeName: "", //false	店铺名称，商户类型为小微商户时必填
        storeAddress: "", //	false	店铺地址，商户类型为小微商户时必填
        storePicture: "", //	false	店铺照片，商户类型为小微商户时必填
        idcardNormalPicture: "", //	true	身份证国徽照片（法人或联系人）
        idcardReversePicture: "", //	true	身份证人像照片（法人或联系人
      },
      // 基本信息详情
      detail: {},
      checkStatus: {
        WAIT: "待审核",
        PASS: "已通过",
        REJECT: "已驳回",
      },
      activateStatus: {
        NOTACTIVATE: "未激活",
        ACTIVATE: "已激活",
        FROZEN: "已冻结",
        CLOSED: "已关闭",
      },
      // 结算信息
      settleColumns: [
        { title: "账户名", key: "accountName", align: "center" },
        { title: "账号", key: "accountNo", align: "center" },
        { title: "联行号", key: "interBranchesNo", align: "center" },
        {
          title: "账户类型",
          key: "accountType",
          align: "center",
          render(h, { row }) {
            let map = {
              COMPANY: "企业",
              PERSONAL: "个人",
            };
            return h("p", map[row.accountType]);
          },
        },
      ],
      settleData: [],
      // 关联分账方
      accountColumns: [
        { title: "商户名称", key: "mchName", align: "center" },
        { title: "分账方编号", key: "allocMchId", align: "center" },
        { title: "三方分账方编号", key: "allocMchNo", align: "center" },
        { title: "操作", key: "action", align: "center", slot: "action" },
      ],
      accountData: [],
      // 电子账簿
      accBookModel: "add",
      accBooChannelColumns: [
        { title: "渠道名称", key: "mchName", align: "center" },
        { title: "商户号", key: "mchChannelId", align: "center" },
        {
          title: "产品信息",
          key: "mchChannelId",
          align: "center",
          slot: "channelConfig",
        },
        { title: "操作", key: "mchChannelId", align: "center", slot: "action" },
      ],
      accountColumns1: [
        { title: "户名", key: "accountName", align: "center" },
        { title: "账号", key: "accountNo", align: "center" },
        { title: "银行", key: "accountBankName", align: "center" },
        { title: "支行", key: "accountBranchBankName", align: "center" },
        { title: "开户地", key: "accountOpen", align: "center" },
        { title: "操作", key: "action", align: "center", slot: "action" },
      ],
      accountColumns2: [
        { title: "收款账号", key: "receiveAccountNo", align: "center" },
        { title: "户名", key: "receiveAccountName", align: "center" },
        { title: "开户行", key: "receiveAccountBankName", align: "center" },
        { title: "操作", key: "action", align: "center", slot: "action" },
      ],
      tableData1: [],
      tableData2: [],
      accBooChannel: [],
      accountBook: {
        accountName: "", //户名
        accountNo: "", //账号
        accountBankName: "", //银行
        accountBranchBankName: "", //支行
        accountOpen: "", //开户地
        aesKey: "",
        channelStatus: "ACTIVATED", // ACTIVATED（激活）、FROZEN（冻结）
        id: "", //商户Id
        mchChannelId: "", //商户号
        mchChannelName: "", //商户名称
        mchId: "",
        mchKey: "",
        mchPrivateKey: "", //商户RSA私钥
        mchPublicKey: "", //商户RSA公钥
        // notifyUrl: "",//入金回调地址
        orgPublicKey: "", //机构RSA公钥
        receiveAccountNo: "", //账号
        receiveAccountName: "", //入金户名
        receiveAccountBankName: "", //开户行
      },
      bookConfig: {
        accountName: "", //户名
        accountNo: "", //账号
        accountBankName: "", //银行
        accountBranchBankName: "", //支行
        accountOpen: "", //开户地
      },
      // 产品信息
      productColumns: [
        {
          title: "商户名称",
          key: "mchName",
          align: "center",
          slot: "mchName",
        },
        {
          title: "产品类型",
          key: "type",
          align: "center",
          render(h, { row }) {
            let map = {
              1: "出金",
              2: "代付",
              3: "转账",
              4: "结算",
            };
            return h("p", map[row.type]);
          },
        },
        {
          title: "状态",
          key: "status",
          align: "center",
          render(h, { row }) {
            return h("p", `${Boolean(row.status) ? "已开启" : "已关闭"}`);
          },
        },
      ],
      productData: [],
      // 筛选项
      mchType: [
        {
          title: "企业",
          value: "ENTERPRISE",
        },
        {
          title: "个体工商户",
          value: "INDIVIDUAL_BUSINESS",
        },
        {
          title: "小微商户",
          value: "MICRO_ENTERPRISE",
        },
        {
          title: "事业单位",
          value: "PUBLIC_INSTITUTION",
        },
        {
          title: "民办非企业单位",
          value: "PRIVATE_NONENTERPRISE_UNIT",
        },
        {
          title: "社会团体",
          value: "SOCIAL_ORGANIZATION",
        },
      ],
      microType: [
        {
          title: "店铺场所",
          value: "MICRO_TYPE_STORE",
        },
        {
          title: "流动经营/便民服务",
          value: "MICRO_TYPE_MOBILE",
        },
        {
          title: "线上商品/服务交易",
          value: "MICRO_TYPE_ONLINE",
        },
      ],
      licenceType: [
        "统一社会信用代码证书",
        "有偿服务许可证（军队医院适用）",
        "医疗机构执业许可证（军队医院适用）",
        "企业营业执照（挂靠企业的党组织适用）",
        "组织机构代码证（政府机关适用）",
        "社会团体法人登记证书",
        "民办非企业单位登记证书",
        "基金会法人登记证书",
        "慈善组织公开募捐资格证书",
        "农民专业合作社法人营业执照",
        "宗教活动场所登记证",
        "营业执照",
        "营业执照号码（多合一）",
        "事业单位法人证书",
        "其他证书/批文/证明",
      ],
      industrysType: industry,
      address,
      provinceOption: address,
      cityOption: [],
      countyOption: [],
      // 新增分账方信息
      addUserShow: false,
      mchFormData: {
        mchName: "",
        allocMchId: "",
      },
      subNumber: [],
      // 新增电子
      addAccBooChannelShow: false,
      addAccBooConfigShow: false,
      addAccBooConfigShow2: false,
      // 图片上传
      uploadUrl: URL_API.upload,
      mchProtocolList: [],
      otherPictureList: [],

      placementRight: "bottom-start",
      placementLeft: "bottom-start",
    };
  },
  computed: {
    ...mapState({
      businessData: "business_data",
    }),
  },

  created() {
    this.type = this.$route.query.type || "detail";

    if (this.businessData) {
      const { mchDetail, mchAllocAccount, mchProfileSupplementPO } =
        this.businessData;
      this.formData = mchDetail;
      this.accountData = mchAllocAccount;
      this.attachFormData = mchProfileSupplementPO;
    }

    if (this.type === "detail") {
      this.getDetail();
    }

    const { activateStatus } = JSON.parse(sessionStorage.getItem("userInfo"));
    this.activateStatusSwitch = activateStatus;
  },

  mounted() {
    const updateDatePicker = () => {
      if (document.body.clientWidth <= 1900) {
        this.placementRight = "bottom-end";
      } else {
        this.placementRight = "bottom-start";
      }
      if (document.body.clientWidth <= 1505) {
        this.placementLeft = "bottom-end";
      } else {
        this.placementLeft = "bottom-start";
      }
    };
    updateDatePicker();
    window.onresize = () => {
      return (() => {
        updateDatePicker();
      })();
    };
  },

  destroyed() {
    if (this.type === "add") {
      let data = {
        mchDetail: this.formData,
        mchAllocAccount: this.accountData,
        mchProfileSupplementPO: this.attachFormData,
      };
      this.setBusinessData(data);
    }
  },

  methods: {
    ...mapMutations({ setBusinessData: "set_business_data" }),
    // 获取详情
    getDetail() {
      shopDetail(this.$route.query.id).then((res) => {
        if (res && res.resultStatus) {
          this.detail = res.resultData.mchDetail || {};
          this.formData.mchName = this.detail.mchName;
          this.accountData = [res.resultData.mchAllocAccount] || [];
          if (this.accountData.length > 0) {
            this.accountData[0].mchName = this.detail.mchName;
          }
          this.mchProtocolList =
            (this.detail.mchProtocol && JSON.parse(this.detail.mchProtocol)) ||
            [];
          this.otherPictureList =
            (this.detail.otherPicture &&
              JSON.parse(this.detail.otherPicture)) ||
            [];
          this.settleData = res.resultData.settlements || [];
          this.accBooChannel =
            (res.resultData.mchTradeChannel && [
              res.resultData.mchTradeChannel,
            ]) ||
            [];
          this.productData = res.resultData.mchFeeAccount || [];
          this.attachFormData = res.resultData.mchSupplement || {};
          if (this.detail.checkStatus === "PASS") {
            this.accountColumns = this.accountColumns.filter(
              (item) => item.key !== "action"
            );
          }
          const mchTradeChannel = res.resultData.mchTradeChannel;
          if (
            mchTradeChannel.accountName ||
            mchTradeChannel.accountNo ||
            mchTradeChannel.accountBankName ||
            mchTradeChannel.accountBranchBankName ||
            mchTradeChannel.accountOpen
          ) {
            this.tableData1 = [mchTradeChannel];
            console.log("this.tableData1", this.tableData1);
          }
          if (
            mchTradeChannel.receiveAccountNo ||
            mchTradeChannel.receiveAccountName ||
            mchTradeChannel.receiveAccountBankName
          ) {
            this.tableData2 = [mchTradeChannel];
          }
          this.bookConfig = mchTradeChannel;
          console.log("商户详情信息", this.detail);
        }
      });
    },

    // 点击导航
    handleClickTab(tab) {
      this.curTab = tab;
      if (this.type === "add") {
        if (this.curTab !== "tab1" && !this.validate("tab1")) {
          this.$nextTick(() => {
            this.curTab = "tab1";
          });
          return;
        }
        if (["tab5", "tab6"].includes(this.curTab) && !this.validate("tab3")) {
          this.$nextTick(() => {
            this.curTab = "tab3";
          });
          return;
        }
      }
      if (this.type === "edit") {
        this.type = "detail";
      }
    },
    handleClickEdit() {
      this.type = "edit";
      this.formData = Object.assign({}, this.detail);
    },
    handleClickCancel() {
      this.type = "detail";
      this.getDetail();
    },
    // 新增商户
    handleClickSubmit() {
      let accBooChannel = JSON.parse(JSON.stringify(this.accBooChannel));
      if (this.accBooChannel.length == 0) {
        return this.$Message.error("电子账簿信息不能为空");
      }
      accBooChannel[0] = {
        ...accBooChannel[0],
        ...this.tableData1[0],
        ...this.tableData2[0],
      };
      let data = {
        mchDetail: this.formData,
        mchAllocAccount: this.accountData[0],
        mchProfileSupplementPO: this.attachFormData,
        mchTradeChannel: accBooChannel[0],
      };

      console.log("提交的数据", data);

      if (this.type === "add") {
        addShop(data).then((res) => {
          if (res && res.resultStatus) {
            this.$router.push({
              name: "businessList",
            });
            this.setBusinessData(null);
          }
        });
      }
      if (this.type === "edit") {
        putsupplementEidt(this.$route.query.id, this.attachFormData).then(
          (res) => {
            if (res && res.resultStatus) {
              this.type = "detail";
              this.$Message.success("保存成功");
              this.getDetail();
            }
          }
        );
      }
    },
    // 编辑基本信息
    onSubmit() {
      if (!this.validate("tab1")) {
        return;
      }
      console.log(this.formData.legalPersonCemEndDate);

      let data = Object.assign({}, this.formData);
      console.log("编辑后提交的商户基本信息数据", data);

      setShop(this.$route.query.id, data).then((res) => {
        if (res && res.resultStatus) {
          this.$Message.success("保存成功");
          this.type = "detail";
          this.getDetail();
        }
      });
    },

    // 审核商户
    auditShop(val) {
      if (val == "REJECT") {
        auditShop(this.$route.query.id, {
          checkStatus: val,
        }).then((res) => {
          if (res.resultStatus == true) {
            this.$message.success("审核成功");
            this.getDetail();
          }
        });
      } else {
        auditShop(this.$route.query.id, {
          checkStatus: val,
        }).then((res) => {
          if (res.resultStatus == true) {
            this.$message.success("审核成功");
            this.getDetail();
          }
        });
      }
    },
    // 激活/冻结商户
    setShopStatus(val) {
      this.$confirm({
        isShow: true,
        message: "此操作将修改状态, 是否继续?",
      })
        .then(() => {
          setShopStatus(this.$route.query.id, {
            activateStatus: val,
          }).then((res) => {
            if (res.resultStatus) {
              switch (val) {
                case "ACTIVATE":
                  this.$message.success("激活成功");
                  break;
                case "FROZEN":
                  this.$message.success("冻结成功");
                  break;
                case "CLOSED":
                  this.$message.success("关闭成功");
                  break;
              }
              this.getDetail();
            }
          });
        })
        .catch(() => {
          this.$Message.warning("已取消操作");
        });
    },
    // 重发邮件
    reEmail() {
      resend(this.$route.query.id).then((res) => {
        if (res.resultStatus) {
          this.$message.success("重发邮件请求成功");
        }
      });
    },

    // 点击展示图片
    handlePreview(e) {
      this.showImg = e.url;
    },

    beforeUpload(file) {
      this.isImge = true;
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = ["jpg", "jpeg", "png", "bpm"].includes(
        testmsg.toLocaleLowerCase()
      );
      if (!extension) {
        this.isImge = false;
        this.$Message.error("上传图片支持jpg/jpeg/png/bpm格式");
      }
      if (!extension) {
        return false;
      }
      return new Promise((resolve) => {
        let isLt2M = file.size / 1024 < 2048; // 判定图片大小是否小于2MB = 2048kb
        if (isLt2M) {
          resolve(file);
        }
        // 压缩到2048KB,这里的2048就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 1024).then((res) => {
          const fileOfBlob = new File(
            [res],
            `${new Date().getTime()}.${testmsg}`
          );
          resolve(fileOfBlob);
        });
      });
    },
    // 商户证件上传
    licencePictureSuccess(res) {
      if (res && res.resultStatus) {
        this.formData.licencePicture = res.resultData.fileLink;
      }
    },
    // 身份证国徽照片（法人或联系人）上传
    idcardNormalPictureSuccess(res) {
      if (res && res.resultStatus) {
        this.formData.idcardNormalPicture = res.resultData.fileLink;
      }
    },
    // 身份证人像照片（法人或联系人）上传
    idcardReversePictureSuccess(res) {
      if (res && res.resultStatus) {
        this.formData.idcardReversePicture = res.resultData.fileLink;
      }
    },
    // 开户许可证上传
    accountOpeningLicenseSuccess(res) {
      if (res && res.resultStatus) {
        this.formData.accountOpeningLicense = res.resultData.fileLink;
      }
    },
    // 店铺照片上传
    storePictureSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.storePicture = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    // 商户协议上传
    mchProtocolSuccess(res) {
      if (this.isImge && res.resultStatus) {
        let data = {
          name: res.resultData.fileName,
          url: res.resultData.fileLink,
        };
        this.mchProtocolList.push(data);
      } else {
        return false;
      }
    },
    // 商户协议移除
    mchProtocolRemove(res, files) {
      this.mchProtocolList = [];
      files.forEach((v) => {
        let data = {
          name: v.name,
          url: v.url,
        };
        this.mchProtocolList.push(data);
      });
    },
    // 其他材料上传
    otherPictureSuccess(res) {
      if (res.resultStatus) {
        let data = {
          name: res.resultData.fileName,
          url: res.resultData.fileLink,
        };
        this.otherPictureList.push(data);
      }
    },
    // 其他材料移除
    otherPictureRemove(res, files) {
      this.otherPictureList = [];
      files.forEach((v) => {
        let data = {
          name: v.name,
          url: v.url,
        };
        this.otherPictureList.push(data);
      });
    },

    validate(tab = "tab1") {
      let formData = JSON.parse(JSON.stringify(this.formData));
      if (tab === "tab1") {
        if (!formData.mchName) {
          this.$message.error("商户名称必填");
          return false;
        }
        if (!formData.mchShortName) {
          this.$message.error("商户简称必填");
          return false;
        }
        if (!formData.mchType) {
          this.$message.error("商户类型必选");
          return false;
        }
        if (formData.mchType == "MICRO_ENTERPRISE") {
          if (!formData.microType) {
            this.$message.error("经营类型必选");
            return false;
          }
          if (!formData.storeAddress) {
            this.$message.error("店铺地址必填");
            return false;
          }
          if (!formData.storeName) {
            this.$message.error("店铺名称必填");
            return false;
          }
          if (!formData.idcardNo) {
            this.$message.error("联系人身份证号码必填");
            return false;
          } else {
            if (formData.idcardNo.indexOf("*") != -1) {
              console.log("联系人身份证号码有星号,判定为未做修改");
            } else {
              if (
                !/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
                  formData.idcardNo
                )
              ) {
                this.$message.error("请输入合法的身份证号码");
                return false;
              }
            }
          }
          if (!formData.idcardReversePicture) {
            this.$message.error("联系人身份证人像照片必传");
            return false;
          }
          if (!formData.idcardNormalPicture) {
            this.$message.error("联系人身份证国徽照片必传");
            return false;
          }
          if (!formData.storePicture) {
            this.$message.error("店铺照片必传");
            return false;
          }
        } else {
          if (!formData.licenceType) {
            this.$message.error("商户证件类型必选");
            return false;
          }
          if (!formData.licenceNo) {
            this.$message.error("商户证件编号必填");
            return false;
          }
          if (!formData.legalPersonName) {
            this.$message.error("法人姓名必填");
            return false;
          }
          if (!formData.idcardNo) {
            this.$message.error("法人身份证号码必填");
            return false;
          } else {
            if (formData.idcardNo.indexOf("*") != -1) {
              console.log("法人身份证号码有星号,判定为未做修改");
            } else {
              if (
                !/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
                  formData.idcardNo
                )
              ) {
                this.$message.error("请输入合法的身份证号码");
                return false;
              }
            }
          }
          if (!formData.licencePicture) {
            this.$message.error("商户证件照片必传");
            return false;
          }
          if (!formData.idcardReversePicture) {
            this.$message.error("法人身份证人像照片必传");
            return false;
          }
          if (!formData.idcardNormalPicture) {
            this.$message.error("法人身份证国徽照片必传");
            return false;
          }
          if (!formData.accountOpeningLicense) {
            this.$message.error("开户许可证必传");
            return false;
          }
        }
        if (!formData.contactName) {
          this.$message.error("联系人必填");
          return false;
        }
        if (!formData.contactTel) {
          this.$message.error("手机号码必填");
          return false;
        } else {
          if (formData.contactTel.indexOf("*") != -1) {
            console.log("手机号码有星号,判定为未做修改");
          } else {
            if (!/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/.test(formData.contactTel)) {
              this.$message.error("请正确填写手机号码");
              return;
            }
          }
        }

        if (!formData.contactEmail) {
          this.$message.error("邮箱必填");
          return false;
        } else {
          if (
            !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              formData.contactEmail
            )
          ) {
            this.$message.error("邮箱格式不合法");
            return;
          }
        }
        if (!formData.servicePhone) {
          this.$message.error("客服电话必填");
          return false;
        } else {
          if (formData.servicePhone.indexOf("*") != -1) {
            console.log("客服电话有星号,判定为未做修改");
          } else {
            if (!/^[0-9\-]+$/.test(formData.servicePhone)) {
              this.$message.error("请正确填写客服电话");
              return;
            }
          }
        }
        if (!formData.contactAddress) {
          this.$message.error("联系地址必填");
          return false;
        }
        console.log("this.mchProtocolList", this.mchProtocolList);
        if (this.mchProtocolList.length > 0) {
          this.formData.mchProtocol = JSON.stringify(this.mchProtocolList);
        }

        if (this.otherPictureList.length > 0) {
          this.formData.otherPicture = JSON.stringify(this.otherPictureList);
        }
        return true;
      }

      if (tab === "tab3") {
        if (this.accountData.length === 0) {
          this.$Message.error("分账方信息必须添加，且只能添加一条");
          return false;
        }
      }

      return true;
    },
    nextStep(tab = "tab1") {
      switch (tab) {
        case "tab1":
          if (!this.validate("tab1")) {
            return;
          }
          this.curTab = "tab3";
          break;
        case "tab3":
          if (!this.validate("tab3")) {
            return;
          }
          this.curTab = "tab4";
          break;
      }
    },

    addAccBooChannel() {
      this.addAccBooChannelShow = true;
      this.mchChannelId = this.accountBook.mchChannelId;
      this.accountBook.mchName = this.formData.mchName;
    },
    editAccBooChannel(row) {
      this.addAccBooChannelShow = true;
      this.accountBook = row;
      this.mchChannelId = this.accountBook.mchChannelId;
    },
    // 新增分账方
    addAccount() {
      this.addUserShow = true;
      this.mchFormData.mchName = this.formData.mchName;
      this.searAllocFn();
    },
    editAccount(row) {
      this.addUserShow = true;
      this.mchFormData = row;
      this.searAllocFn();
    },
    editBookAccount(type, row) {
      if (type == "tableData1") {
        this.addAccBooConfigShow = true;
      }
      if (type == "tableData2") {
        this.addAccBooConfigShow2 = true;
      }
      this.bookConfig = JSON.parse(JSON.stringify(row));
    },
    deleteBookAccount(type) {
      this.$confirm({
        isShow: true,
        message: "此操作将删除该配置, 是否继续?",
      })
        .then(() => {
          if (type == "tableData1") {
            this.bookConfig.accountBankName = "";
            this.bookConfig.accountName = "";
            this.bookConfig.accountNo = "";
            this.bookConfig.accountBranchBankName = "";
            this.bookConfig.accountOpen = "";
            this.tableData1 = [];
          }
          if (type == "tableData2") {
            this.bookConfig.receiveAccountNo = "";
            this.bookConfig.receiveAccountBankName = "";
            this.bookConfig.receiveAccountName = "";
            this.tableData2 = [];
          }
          if (this.type != "add") {
            postAccountBookChannelAdd(
              this.$route.query.id,
              this.bookConfig
            ).then((res) => {
              if (res) {
                this.getDetail();
                this.$Message.success("删除成功");
              }
            });
          }
        })
        .catch(() => {
          this.$Message.warning("已取消删除");
        });
    },
    searAllocFn() {
      let mchName = this.detail.mchName;
      if (this.type === "add") {
        mchName = this.mchFormData.mchName;
      }
      searAlloc(mchName).then((res) => {
        if (res && res.resultStatus) {
          this.subNumber = res.resultData.mchAllocAccount;
        }
      });
    },
    sureAdd() {
      let data = Object.assign({}, this.mchFormData);
      console.log(data);
      this.subNumber.forEach((item) => {
        if (item.allocMchId == this.mchFormData.allocMchId) {
          data.allocMchNo = item.allocMchNo;
        }
      });

      if (!data.allocMchId) {
        this.$Message.error("分账方编号必填");
        return;
      }

      if (this.$route.query.id) {
        mchAllocEdit(this.$route.query.id, data).then((res) => {
          if (res && res.resultStatus) {
            this.$Message.success("保存成功");
            this.getDetail();
            this.addUserShow = false;
          }
        });
      } else {
        this.accountData = [data];
        this.addUserShow = false;
      }
    },
    sureAddBook() {
      let data = JSON.parse(JSON.stringify(this.accountBook));
      data.mchChannelId = this.mchChannelId;
      if (!data.mchChannelId || !/^[0-9]*$/.test(data.mchChannelId)) {
        this.$message.error("商户号必填，且为正整数");
        return false;
      }
      if (data.id) {
        if (data.mchKey === this.oldBookKey) {
          delete data.mchKey;
        }
      }
      this.buttonFlag = true;

      if (this.type == "add") {
        this.accBooChannel = [data];
        this.$message.success("保存成功");
        setTimeout(() => {
          this.buttonFlag = false;
        }, 2000);
        this.addAccBooChannelShow = false;
      } else {
        postAccountBookChannelAdd(this.$route.query.id, data).then((res) => {
          if (res.resultStatus) {
            this.$message.success("保存成功");
            this.getDetail();
            this.addAccBooChannelShow = false;
          }
          setTimeout(() => {
            this.buttonFlag = false;
          }, 2000);
        });
      }
    },
    sureAddBookConfig(modalType = 1) {
      if (this.type == "add") {
        if (modalType == 1) {
          this.tableData1 = [this.bookConfig];
        }
        if (modalType == 2) {
          this.tableData2 = [this.bookConfig];
        }
        this.addAccBooConfigShow = false;
        this.addAccBooConfigShow2 = false;
      } else {
        postAccountBookChannelAdd(this.$route.query.id, this.bookConfig).then(
          (res) => {
            if (res.resultStatus) {
              this.$message.success("保存成功");
              this.getDetail();
              this.addAccBooConfigShow = false;
              this.addAccBooConfigShow2 = false;
            }
            setTimeout(() => {
              this.buttonFlag = false;
            }, 2000);
          }
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.tab-label {
  min-width: 120px;
  height: 38px;
  line-height: 38px;
  text-align: right;
  margin-right: 10px;
  white-space: nowrap;
  i {
    color: var(--themeColor);
    margin-right: 2px;
  }
}
.tab3-form {
  padding: 0 80px;
  .tab-label {
    width: 82px;
    min-width: 82px;
  }
}
.tab3-form-account {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  .tab-label {
    text-align: left;
  }
  /deep/ .ivu-form-item-content {
    flex-wrap: wrap;
    width: 200px;
  }
}
.container {
  overflow: hidden;
  background: #fff;

  .status {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: rgba(249, 249, 249, 1);
    margin-bottom: 30px;
    font-size: 15px;
    .label {
      width: 120px;
      font-weight: 500;
      color: var(--label);
    }
    .check-msg {
      color: var(--label);
      margin-left: 10px;
    }
  }
  .title {
    color: var(--label);
    padding-left: 0;
  }
  .edit-btn {
    font-size: 16px;
    width: 90px;
    height: 36px;
  }
  .email-btn {
    color: #fff;
    background-color: var(--themeColor);
    margin-right: 10px;
    padding: 0;
  }
  .table {
    min-height: 800px;
  }
  .tab1 {
    &-input {
      width: 100%;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(239, 239, 239, 1);
      border-radius: 4px;
      padding: 20px 30px 0px;
      .form {
        // overflow: hidden;
        height: 480px;
      }
      .form-item {
        float: left;
        width: 50%;
        display: flex;
        align-items: center;
        height: 40px;
        margin-bottom: 20px;
        .inputs {
          width: calc(100%);
          height: 36px;
          background: rgba(255, 255, 255, 1);
          border-radius: 4px;
          margin-left: 0;
        }
        .split {
          height: 40px;
          line-height: 40px;
          margin: 0 10px;
        }
        .date-picker {
          flex: 1;
        }
      }
      .form-address {
        display: flex;
        .tab-label {
          // width: 125px;
        }
        .form-date-picker {
          display: flex;
          width: calc(100%);
        }
        /deep/ .bottom-end .ivu-select-dropdown {
          left: auto !important;
          right: 0 !important;
        }
      }
      .input-box > li > span {
        margin-right: 10px;
        width: 150px;
        display: block;
        text-align: right;
      }
    }
    &-footer {
      display: flex;
      justify-content: center;
      margin-top: 50px;
      margin-bottom: 18px;
      button {
        width: 120px;
      }
      button:last-child {
        margin-left: 10px;
      }
    }
    &-upload {
      overflow: hidden;
      width: 100%;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(239, 239, 239, 1);
      border-radius: 4px;
      padding: 20px 30px;
      margin-top: 20px;
      .upload-form-item {
        float: left;
        width: 140px;
        margin-right: 30px;
        span {
          white-space: nowrap;
        }
      }
      .upload-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 140px;
        position: relative;
        img {
          width: 100%;
        }
        .icon-delete {
          position: absolute;
          right: 0;
          top: 4px;
        }
      }
    }
  }
  .add-user-btn {
    display: block;
    margin-top: 10px;
  }
  .attach-table {
    width: 80%;
    margin: 0 auto;
    border-collapse: collapse;
    border: 1px solid #eee;
    font-weight: 400;
    color: var(--label);
    th {
      font-weight: 400;
      padding: 30px 0;
      border: 1px solid #eee;
    }
    td {
      padding: 30px 0;
      border: 1px solid #eee;
      text-align: center;
    }
    .type {
      display: block;
      padding: 30px 0;
    }
    .content {
      width: 60%;
    }
  }
}
/deep/ .ivu-table:before {
  display: none;
}
.upload_wj {
  margin-bottom: 25px;
  width: 100%;
  padding-bottom: 25px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
  margin-top: 20px;
}
.upload_wj_left {
  float: left;
  margin-left: 44px;
  width: 22%;
  height: 100%;
}
.upload_wj_left i {
  color: var(--themeColor);
  padding-right: 3px;
}
.upload_wj_right {
  float: left;
  margin-left: 200px;
  width: 22%;
  height: 100%;
}

.upload_wj h5 {
  padding: 29px 0 19px 0;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}
.outer-div {
  padding: 24px;
  margin: 0 auto;
}
.header {
  height: 37px;
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 24px;
}
ul li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tab-tilte {
  letter-spacing: 0;
  width: 90%;
}
.tab-tilte li {
  float: left;
  font-size: 14px;
  color: #515a6e;
  line-height: 35px;
  text-align: center;
  cursor: pointer;
  span {
    padding: 0 12px;
  }
}

.overBt {
  float: right;
  width: 60px;
  height: 32px;
  margin-left: 10px;
  padding: 0;
  background-color: #aaaaaa;
  border-color: #aaaaaa;
  font-size: 12px;
  color: #ffffff;
  border-radius: 3px;
}
.returnBt {
  float: right;
  margin-left: 10px;
}

.returnBt.active {
  background-color: #aaaaaa;
  border-color: #aaaaaa;
  font-size: 12px;
  color: #ffffff;
}

.overBt.active {
  background: var(--themeColor);
  border-color: var(--themeColor);
}
/* 点击对应的标题添加对应的背景颜色 */
.tab-tilte .active {
  color: #3385ff;
  border-bottom: 2px solid #3385ff;
}
</style>
<style>
.ivu-tooltip-inner {
  max-width: none;
}
.ivu-form-item-label {
  width: 150px !important;
}
.ivu-form-item-content {
  display: flex;
  flex: 1;
  margin-left: 0 !important;
}
.tab3-form {
  padding: 0 20px;
}
.tab3 .ivu-form-item-label {
  width: 100px !important;
}
</style>
